import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Helmet } from "react-helmet";
import {
  IoIosCopy,
} from "react-icons/io";

import { Button, Container, Wrapper, Row, Box } from "../../components/util";
import { Breadcrumb, Accordion, ProductsCard, PartnerHelpFooter } from "../../components/site";
import Layout from "../../components/layout";
import Seo from "../../components/seo";


import PayHeroLogo from "../../images/logos/PayHero/PayHero_Full.svg";
import DroppahLogo from "../../images/logos/Droppah/Droppah_Full_Black.svg";
import InvoxyLogo from "../../images/logos/Invoxy/Invoxy_Full.svg";



import Image from "../../components/image";
const MarketingRow = styled.div`
  
  padding: 20px;
  box-shadow: 0px 12px 17.5px 0px #00000040;
  width: 65%;
  background: ${({ backgroundColor }) => backgroundColor};
  min-height: 325px;

  @media(max-width: ${(props) => props.theme.breakpoints.mobile}){
    width: 90%;
  }
  
  .-textRight {
    margin-left: auto;
    text-align: left !important;
    padding-left: 20px;

    h2 {
      text-align: left;
    }
    h4 {
      text-align: left;
    }
  }

  .-textLeft {
    padding-right: 20px;
  }


  h2 {
    color: white;
    text-align: right;
  }
  h4 {
    color: white;
    padding-top: 10px;
    text-align: right;
  }

  a {
    color: white;
  }

  .-textContainer {
    width: 55%;
    padding: 20px;

    @media(max-width: ${(props) => props.theme.breakpoints.mobile}){
      width: 100%;
    }
  }

  .-linkContainer {
    width: 55%;

    @media(max-width: ${(props) => props.theme.breakpoints.mobile}){
      width: 70%;
    }
  }
`;

const MarketingWrapper = styled(Wrapper)`
  max-width: none;
  
  padding-left: 0;
  padding-right: 0;

  > div {
    margin-top: 0px;
  }
  .-right {
    
    margin-right: 30px;
    margin-top: -290px !important;
    border-radius: 25px; 
    > div {
      margin-left: auto;
      border-radius: 25px;
    }

    @media(max-width: ${(props) => props.theme.breakpoints.mobile}){
      margin-top: -24% !important;
      margin-right: 5px;
      > div {
        max-width: 30%;
      }
    }
  }

  .-left {
    margin-left: 30px;

    margin-bottom: -290px !important;
    border-radius: 25px; 
    > div {
      margin-right: auto;
      border-radius: 25px;
    }

    @media(max-width: ${(props) => props.theme.breakpoints.mobile}){
      margin-bottom: -320px !important;
      margin-left: 5px;
      > div {
        max-width: 30%;
      }
    }
  }

  .left-container {
    @media(max-width: ${(props) => props.theme.breakpoints.mobile}){
      margin-top: 275px;
    }
  }
  img {

  }

  .-marketRowRight {
    margin-left: auto;
    border-top-left-radius: 25px;
    border-bottom-left-radius: 25px;
  }

  .-marketRowLeft {
    margin-right: auto;
    border-top-right-radius: 25px;
    border-bottom-right-radius: 25px;
  }

`;

const WebsiteP = styled.p`
  width: 60%;
  margin-left: auto;
  margin-right: auto;
`;

const BlurbBox = styled(Box)`
  h2,
  p,
  li {
    text-align: left;
  }
`

const CopyButton = (props) => {
  const [copySuccess, setCopySuccess] = useState("");

  function copyToClip() {
    var blurb = document.getElementById("blurb_" + props.product).innerHTML;

    function listener(e) {
      e.clipboardData.setData("text/html", blurb);
      e.clipboardData.setData("text/plain", blurb);
      e.preventDefault();
    }
    document.addEventListener("copy", listener);
    document.execCommand("copy");
    document.removeEventListener("copy", listener);

    setCopySuccess(true);
    setTimeout(function () {
      setCopySuccess(false);
    }, 4000);
  }

  return (
    <Button
      className="grey -md -full"
      onClick={() => {
        copyToClip();
      }}
      css={{ marginTop: "50px" }}
      atag
    >
      <IoIosCopy css={{ top: "2px", marginRight: "8px" }} />{" "}
      {copySuccess ? "Copied" : "Copy Description"}
    </Button>
  );
};

const Marketing = (props) => {
  useEffect(() => {
    if (typeof window !== "undefined" && typeof document !== "undefined") {
      if (props.location.search.substr(1) === "reload") {
        window.history.pushState(
          {},
          null,
          props.location.origin + props.location.pathname
        );
        window.location.reload(true);
      }
    }
  }, []);

  return (
    <Layout>
      <Seo
        title="Marketing Resources | Partner Resources | FlexiTime Partner"
        pathname={props.location.pathname}
      />
      <Helmet>
        <meta name="robots" content="noindex" />
      </Helmet>
      <Breadcrumb
        parentPage="Partner Resources"
        parentPageLink="/partner-resources"
        currentPage="Marketing Resources"
      />
      <Container className="border-bottom">
        <Wrapper stackGap={20}>
          <Box stackGap={70}>
            <h1 className="-textCenter">Marketing Resources</h1>
          </Box>
        </Wrapper>
      </Container>
      <Container>
        <Wrapper className="-center" maxWidth={1000} style={{
          justifyContent: 'center',
          display: 'flex'
        }}>
          <Box className="-textCenter" stackGap={30} size={75}>
            <h4>The FlexiTime Partner Program rewards you with a range of benefits to help your practice and your clients succeed.</h4>
            <h4>
              We want to make it easy for you to promote our products to your clients and potential clients so we can grow together.
            </h4>
          </Box>
        </Wrapper>
      </Container>


      <Container className="-textCenter">
        <div style={{
          background: '#00ADEF1A',
        }}>
          <MarketingWrapper>
            <div>
              <MarketingRow className="-marketRowRight" backgroundColor={'#004763'}>
                <div>
                  <div className="-textContainer">
                    <h2>Access Marketing Funding</h2>
                    <h4>Gold and Silver partners can access a fund for qualifed marketing activites that feature FlexiTime products.</h4>
                  </div>
                  <div className="-linkContainer -textLeft">
                    <h4><a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://www.flexitime.works/media/partners/FlexiTime_PartnerComarketingFund_Guide.pdf"
                    >
                      FlexiTime Co-Marketing Fund Guide
                    </a></h4>
                    <h4><a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://docs.google.com/forms/d/e/1FAIpQLSeUKqS_yXcBWR9qUP3LpL4lJTl8x7Q8khXrfFlGKsogG3WevA/viewform"
                    >
                      FlexiTime Marketing Fund Application Form
                    </a></h4>
                  </div>

                </div>
              </MarketingRow>
              <div className="-right">
                <Image
                  filename="Marketing Image.png"

                  alt="Online Payroll & Workforce Management Solutions for Flexible Employees"
                  addShadow
                  maxWidth={'27%'}
                />
              </div>
            </div>

            <div className="left-container">
              <div className="-left">
                <Image
                  filename="Partner_Directory_Image.png"

                  alt="Online Payroll & Workforce Management Solutions for Flexible Employees"
                  addShadow
                  maxWidth={'27%'}
                />
              </div>
              <MarketingRow className="-marketRowLeft" backgroundColor={'#FF6622'}>
                <div>
                  <div className="-textContainer -textRight">
                    <h2>Showcase Your Partnership</h2>
                    <h4>Grow trust with your clients by displaying product logos. Plus, be found by future clients on our <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://www.payhero.co.nz/partner-directory"
                    >
                      partner directory.
                    </a></h4>
                  </div>
                  <div className="-linkContainer -textRight">
                    <h4><a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="/media/Product_Logos.zip"
                    >
                      Product Logos
                    </a></h4>
                    <h4><a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="/media/FlexiTime_Product_Screenshots.zip"
                    >
                      Product Screenshots
                    </a></h4>
                    <h4><a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://docs.google.com/forms/d/e/1FAIpQLSdISbtgLvY8tut7zUTs0qJlqawqkTaCpTE-D_nYr9jGvaaGIw/viewform"
                    >
                      FlexiTime Partner Directory Form
                    </a></h4>
                  </div>
                </div>
              </MarketingRow>
            </div>
          </MarketingWrapper>
        </div>
      </Container>

      <ProductsCard stackGap={50} paddingBottom={30}></ProductsCard>

      <Container className="-textCenter">
        <Wrapper>
          <Box stackGap={30}>
            <Box>
              <h2 className="-fontOrange">FlexiTime On Your Website</h2>
              <WebsiteP>
                When promoting FlexiTime or our products on your website,
                please use the following descriptions accompanied by the
                correct logo (as shown below). You can download our logos{" "}
                <a href="/media/Product_Logos.zip" download>
                  here
                </a>
                .
              </WebsiteP>
            </Box>
            <Accordion title="PayHero">
              <Row stackGap={20} alignCenter>
                <Box size={50}>
                  <img
                    className="-center"
                    src={PayHeroLogo}
                    alt="PayHero"
                    height="60"
                  />
                </Box>
                <BlurbBox size={50} id="blurb_PayHero">
                  <h2>About PayHero</h2>
                  <p>
                    PayHero makes payroll simpler and more intuitive for New Zealand employers, especially for companies with part-time, casual, contract and variable-hour staff. PayHero was named as <a href="https://www.payhero.co.nz/blog/payhero-payroll-software-partner-of-the-year-2022" target="_blank" rel="noopener noreferrer">
                      NZ’s best payroll software
                    </a> in 2021 and 2022, before the award was discontinued.
                  </p>
                  <ul>
                    <li>
                      Easily track hourly work with options for mobile timesheets, GPS or photo time clock.
                    </li>
                    <li>
                      Run your pays on Autopilot without even needing to log in.
                    </li>
                    <li>
                      Calculate compliant holiday leave automatically, even when work patterns change.
                    </li>
                    <li>
                      Assign payroll costs to the general ledger and tracking categories with a deep Xero integration.
                    </li>
                    <li>
                      Automate your payday filing obligations with direct integration to Inland Revenue.
                    </li>
                    <li>
                      An app for employees to view and download payslips, and submit timesheets, expense claims and leave requests
                    </li>
                  </ul>
                  <p>
                    Find out more or try{" "}
                    <a href="https://www.payhero.co.nz" target="_blank">
                      PayHero Payroll Software
                    </a>{" "}
                    for free.
                  </p>
                </BlurbBox>
              </Row>
              <CopyButton product="PayHero" />
            </Accordion>
            <Accordion title="Droppah">
              <Row stackGap={20} alignCenter>
                <Box size={50}>
                  <img
                    className="-center"
                    src={DroppahLogo}
                    alt="Droppah"
                    height="60"
                  />
                </Box>
                <BlurbBox size={50} id="blurb_Droppah">
                  <h2>About Droppah</h2>
                  <p>
                    Droppah helps businesses roster staff while tracking time to control costs and optimise coverage. Droppah also integrates with <a href="https://www.payhero.co.nz/" target="_blank" rel="noopener noreferrer">
                      payroll software PayHero
                    </a> to sync time and employee leave through a deep two-way integration.
                  </p>
                  <ul>
                    <li>
                      AI uses employee skills, availability, shift preferences, wage costs, and other requirements to build highly optimised rosters.
                    </li>
                    <li>
                      Accurately cost staff against sales to ensure you’ve got the right coverage at the right times. Set strict roster start times to eliminate unplanned overtime.
                    </li>
                    <li>
                      Increase your accuracy with an in-built photo time clock for timesheets and breaks.
                    </li>
                    <li>
                      Monitor the day’s attendance & breaks in real-time with a live view.
                    </li>
                    <li>
                      Send manager-approved timesheets directly to payroll. No more paper timesheets, manual calculations or data entry.
                    </li>
                  </ul>
                  <p>
                    Droppah is completely free for companies with up to 10 employees. Find out more or try <a href="https://www.droppah.com" target="_blank" rel="noopener noreferrer">
                      Droppah Rostering Software
                    </a>
                  </p>
                </BlurbBox>
              </Row>
              <CopyButton product="Droppah" />
            </Accordion>
            <Accordion title="Karmly">
              <Row stackGap={20} alignCenter>
                <Box size={50}>
                  <img
                    className="-center"
                    src={InvoxyLogo}
                    alt="Invoxy"
                    height="60"
                  />
                </Box>
                <BlurbBox size={50} id="blurb_Invoxy">
                  <h2>About Karmly</h2>
                  <p>
                    Karmly is a timesheet and billing software for recruitment agencies and professional services. Karmly also integrates with <a href="https://www.payhero.co.nz/" target="_blank" rel="noopener noreferrer">
                      Payhero
                    </a> to sync candidate data, timesheets, leave and expenses, making payroll a whole lot easier.
                  </p>
                  <ul>
                    <li>
                      Get your employees & contractors to track time against specific clients, projects or jobs with mobile timesheets.
                    </li>
                    <li>
                      Make things simple for your clients by letting them review and approve timesheets in a single click from an email.
                    </li>
                    <li>
                      Quickly create and send large batches of invoices to multiple clients, all tailored to their exact requirements. Use the e-Invoicing option to get paid faster than ever.
                    </li>
                    <li>
                      Automate compliance and induction tasks with customisable employee and contractor onboarding.
                    </li>
                    <li>
                      Track staff productivity, revenue and costs in real-time as timesheets are submitted. See forecast revenue and build custom reports.
                    </li>
                  </ul>
                  <p>
                    Find out more or try{" "}
                    <a href="https://www.karmly.com/recruitment" target="_blank">
                      Karmly Contractor Management Software
                    </a>{" "}
                    for free.
                  </p>
                </BlurbBox>
              </Row>
              <CopyButton product="Invoxy" />
            </Accordion>
          </Box>
        </Wrapper>
      </Container>

      <PartnerHelpFooter></PartnerHelpFooter>

    </Layout>
  );
};

export default Marketing;
